import {HttpService} from "../http/HttpService";
import {MaintenanceAndServerTime, MaintenanceWindow, Menu, MenuItemType, Settings} from "../http/protocol";
import {Locale} from "../contenful/types";
// import {S} from "../http/Types";


export const EN : Locale = {code: "en", name: "English", defaultLocale: true, fallback : null};
export const DISABLE_TOASTS = "disableToasts";

export interface SettingsWithSite extends  Settings {
    siteNameId : string;
}
export class SettingsService  {
    private static instance: SettingsService;
    httpBackend : HttpService;
    settingsData?: SettingsWithSite;
    locales: Array<Locale>;
    activeLocale? : Locale;

    countryCode?: string;
    appModeLogo?: string;
    appModeTitle?: string;
    
    private constructor(httpBackend : HttpService) {
        this.httpBackend = httpBackend;
        this.settingsData = undefined;
        this.locales = [];
    }

    static getInstance(httpBackend : HttpService) {
        if(!SettingsService.instance) {
            SettingsService.instance = new SettingsService(httpBackend);
        }
        return SettingsService.instance;
    }
    public setAppModeLogo(url : string) {
        this.appModeLogo = url;
    }
    public getAppModeLogo() {
        return this.appModeLogo;
    }
    public setAppModeTitle(title : string) {
        this.appModeTitle = title;
    }
    public getAppModeTitle() {
        return this.appModeTitle;
    }
    public getMaintenance() : Promise<MaintenanceAndServerTime> {
        return this.httpBackend.get("/maintenance");
    }
    public getSettings(): Promise<SettingsWithSite> {
        return this.httpBackend.getWithSite<SettingsWithSite>("/settings").then((r) => {
            return { ...r.response, siteNameId : r.siteNameId };
        });
    }

    public setSettingsData(data: SettingsWithSite) {
        this.settingsData = data;
    }
    public setCountryCode(code? : string) {
        this.countryCode = code;
    }
    public getCountryCode() {
        return this.countryCode;
    }
    public getSettingsData(): SettingsWithSite | undefined {
        return this.settingsData;
    }
    public setDisableToasts() {
        localStorage.setItem(DISABLE_TOASTS, "true");
    }
    public removeDisableToasts() {
        localStorage.removeItem(DISABLE_TOASTS);
    }
    public isToastsDisabled() {
        return localStorage.getItem(DISABLE_TOASTS) == "true";
    }
    public setActiveLocale(locale : Locale) {
        this.activeLocale = locale;
    }
    public getActiveLocale() {
        if(this.activeLocale) {
            return this.activeLocale;
        } else {
            return this.getDefaultLocale();
        }
    }

    public setLocalesData(data: Array<Locale>) {
        this.locales = data;
    }
    public getDefaultLocale() {
        let defaultLocale = EN;
        if(this.locales) {
            let locale = this.locales.find(l => !!l.defaultLocale);
            if(locale) {
                defaultLocale = locale;
            }
        }
        return defaultLocale;
    }
    public getMenu() : Menu {
        if(!this.settingsData || !this.settingsData.menu) {
            return { items : [{ type : MenuItemType.POKER}, { type : MenuItemType.CASINO }, { type : MenuItemType.SPORTSBOOK},{ type : MenuItemType.RACING}]};
        }
        return this.settingsData.menu;
    }

    public getLocalesData() {
        return this.locales;
    }

    public getLanguageOrder() {
        return this.settingsData?.languageOrder ?? "";
    }

}
